<template>
    <div class="swlang">
        <p class="title" @click.prevent="swlangActive = !swlangActive">
            <img :src="localeimg"></p>
        <div class="options" v-if="swlangActive" v-on-click-outside="closeWidget">
            <p 
                v-for="(lang_img, lang_alias) in langswlist"
                :key="lang_alias"
                @click.prevent="selectLanguage(lang_alias)"
            ><img :src="lang_img"> {{ $t('langs.'+lang_alias) }} </p>
        </div>
    </div>
</template>

<script>
import { langswlist } from '@/lang'
import { vOnClickOutside } from '@vueuse/components'

export default {
    name: 'swlang',
    data() {
        return {
            swlangActive: false,
            langswlist: langswlist,
            locale: "en",
            localeimg: "/img/flags/4x3/gb.svg"
        }
    },
    directives: {
        onClickOutside: vOnClickOutside
    },
    mounted() {
        this.locale = this.$root.$i18n.locale
        this.localeimg = langswlist[this.locale]
    },
    methods: {
        closeWidget(){
            this.swlangActive = false
        },
        selectLanguage(lang_alias){
            this.$root.$i18n.locale = lang_alias
            this.locale = this.$root.$i18n.locale
            this.localeimg = langswlist[this.locale]
            this.swlangActive = !this.swlangActive
            this.$root.$cookies.set('sitelocale', this.locale,"10y")
            localStorage.setItem('sitelocale', this.locale)
            this.$store.commit('setLocale', this.locale)
        }
    }
}
</script>

<style scoped>
    .swlang {
        position: relative;
        margin: 0px;
        margin-right: 0px;
    }

    .swlang .title img{
        width: 20px;
        vertical-align: baseline;
    }

    .swlang p {
        margin: 0;
        cursor: pointer;
    }

    .options {
        background: #ccc;
        border: 0;
        position: absolute;
        top: 30px;
        right: 0;
        width: 100px;
        border-radius: 5px;
    }

    .options p {
        color: #333;
        padding: 5px;
        margin: 5px;
        text-decoration: none;
        display: block;
        width:100%;
    }

    .options p:hover{
        font-weight: bold;
    }

    .options p img {
        width: 20px;
        vertical-align: baseline;
    }
    
</style>