//TODO - добавить обработку хедеров, авторизацию по токену

const apiUrl = '/api/'
const debug = process.env.NODE_ENV !== 'production'

export default {

async getFrApi (reqObj) {
    return new Promise((resolve, reject) => {
        let params = {}
        const methods_list = ['get', 'post', 'put', 'patch', 'delete']
        const params_methods_list = ['post', 'put', 'patch']
        let apiMethod = (reqObj.method) ? reqObj.method.trim().toLowerCase() : 'get'
        if (!apiMethod || !methods_list.contains(apiMethod)) apiMethod = 'get'

        if (!reqObj.url) {
            reject('Incorrect API URL!')
        } 
        if (params_methods_list.contains(apiMethod) && reqObj.params && typeof reqObj.params === 'object') {
            params = reqObj.params
        }
        if (reqObj.token){
            // Авторизация по токену
            const error = 'No token access yet!'
            if (debug) console.log(error)
            reject(error)
        } else {
            // Авторизация через сессию
            // axios.get(apiUrl + 'sanctum/csrf-cookie').then(response => { //Раскомментить, если CSRF в API включим

                if (params_methods_list.contains(apiMethod)) {
                    // Передача с параметрами
                    axios[apiMethod](apiUrl + reqObj.url, params, reqObj.options).then(response => {
                        if (response.data.success){
                            if (debug) console.log(response)
                            resolve(response)
                        } else {
                            this.error = response.data.message
                            if (debug) console.log('No API success: ' + (response.data.message || ""))
                            reject(response)
                        }
                    }).catch((error) => {
                        if (error.response) {
                            if (debug) {
                                // console.error('Error API: ' + error)
                                console.error(error.response)
                                reject(error.response)
                            }
                        } else {
                            if (debug) {
                                const resp = {data: {success: false, message: error}}
                                // console.error('Error API: ' + error)
                                console.error(resp)
                                reject(resp)
                            }
                        }
                    })
                } else {
                    // Передача без параметров
                    axios[apiMethod](apiUrl + reqObj.url, {params: reqObj.params}).then(response => {
                        if (response.data.success){
                            if (debug) console.log(response)
                            resolve(response)
                        } else {
                            this.error = response.data.message
                            if (debug) console.log('No API success: ' + (response.data.message || ""))
                            reject(response)
                        }
                    }).catch((error) => {
                        if (error.response) {
                            if (debug) {
                                // console.error('Error API: ' + error)
                                console.error(error.response)
                                reject(error.response)
                            }
                        } else {
                            if (debug) {
                                const resp = {data: {success: false, message: error}}
                                // console.error('Error API: ' + error)
                                console.error(resp)
                                reject(resp)
                            }
                        }
                    })
                }

            // }).catch((error) => { //Раскомментить, если CSRF в API включим
            //     if (debug) console.error('Error CSRF: ' + error)
            //     reject(error)
            // })
        }
    })
}


}
