<template>
    <a class="nav-link" href="#" role="button" @click="toggle">
        <i class="fa fa-bars"></i>
    </a>
</template>

<script>

import { useStore, mapState } from 'vuex'

export default {
    data() {
        return {
            sidebarElementId: 'sidebar-overlay'
        }
    },
    computed: {
        ...mapState({
            openSidebar: state => state.openSidebar
        }),
    },
    methods: {
        toggle(){
            this.$store.commit('toggleSidebar', !this.isOpen());
        },
        isOpen() {
            return document.getElementsByTagName('body')[0].classList.contains('sidebar-open');
        }
    },
    mounted(){
        const self = this;
        window.onresize = function(event) {
            if((self.isOpen() && window.innerWidth <= 768) || (!self.isOpen() && window.innerWidth > 768))
                self.toggle();
        };

        if(self.isOpen() && window.innerWidth <= 768)
            self.toggle();
    }
}
</script>