<template>
    <component :is="layout">
        <router-view/>
    </component>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout'
import AdminpanelLayout from '@/layouts/AdminpanelLayout'
import MainLayout from '@/layouts/MainLayout'
import { langswlist } from '@/lang'

export default {
    data() {
        return {
            langswlist: langswlist,
            locale: "en",
            localeimg: "/img/flags/4x3/gb.svg"
        }
    },
    mounted(){
        let locale = this.$root.$cookies.get('sitelocale')
        if (!locale && navigator.language && langswlist[navigator.language.slice(0, 2)]) locale = navigator.language.slice(0, 2)
        if (!locale) locale = this.$root.$i18n.locale

        this.$root.$i18n.locale = locale
        this.$root.$cookies.set('sitelocale', locale,"10y")
    },
    computed: {
        layout() {
            return (this.$route.meta.layout || 'empty') + '-layout'
        }
    },
    components: {
        EmptyLayout, AdminpanelLayout, MainLayout
    }
}
</script>

