import { createStore, createLogger } from 'vuex'
import users from './modules/users'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  state: {
    locale: 'ru',
    loaderActive: false,
    darkMode: false,
    openSidebar: true
  },
  mutations: {
    toggleSidebar (state, isOpen) {
      state.openSidebar = isOpen
      if(!isOpen){
        document.getElementsByTagName('body')[0].classList.add('sidebar-collapse');
        document.getElementsByTagName('body')[0].classList.remove('sidebar-open');
      }else{
        document.getElementsByTagName('body')[0].classList.remove('sidebar-collapse');
        document.getElementsByTagName('body')[0].classList.add('sidebar-open');
      }
    },
    setLocale (state, locale) {
      state.locale = locale
    },
    setDarkMode (state, status) {
      state.darkMode = status ? true : false
    },
    setLoader (state, active) {
      state.loaderActive = (active) ? true : false
    }
  },
  actions: {
    setDarkMode ({ commit }, status) {
      commit('setDarkMode', status)
    }
  },
  modules: {
    users
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
