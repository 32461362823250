<template>
    <h1 class="m-0">{{ title }}</h1>
</template>

<script>
export default {
    data: () => ({
    }),
    mounted() {
    },
    computed: {
        title() {
            const title = this.$root.$route.meta.title  || ''
            return (title) ? this.$root.$t('route_headers.' + title) : ''
        }
    }
}
</script>
