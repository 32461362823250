<template>
    <ol v-if="breadcrumbs.length" class="breadcrumb w-100 justify-content-end">
        <li v-for="breadcrumb in breadcrumbs" class="breadcrumb-item">
            <router-link :to="breadcrumb.url">{{ $t('route_headers.' + breadcrumb.title) }}</router-link>
        </li>
        <li v-if="title" class="breadcrumb-item">{{ $t('route_headers.' + title) }}</li>
    </ol>
</template>

<script>
import Router from '@/router'

export default {
    data: () => ({
        routlist: {}
    }),
    mounted() {
        // this.$router.options.routes[0].meta
        this.routlist = this.$router.options.routes.reduce((obj, item) => Object.assign(obj, { [item.name]: item }), {});
    },
    computed: {
        title() {
            return this.$root.$route.meta.title  || ''
        },
        breadcrumbs() {
            const res = []
            if (this.$root.$route.meta.breadcrumbs) {
                this.$root.$route.meta.breadcrumbs.forEach(element => {
                    if (this.routlist[element] && this.routlist[element].meta.title) {
                        res.push({
                            title: this.routlist[element].meta.title, 
                            url: this.$router.resolve({name: this.routlist[element].name})
                        })
                    }
                });
            }
            return res
        }
    }
}
</script>