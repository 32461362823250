<template>
    <div class="day-night-switcher">
        <a class="nav-link" href="#" role="button" @click="changeMode()">
            <i v-if="!darkMode" class="fa fa-moon-o" aria-hidden="true"></i>
            <i v-else class="fa fa-sun-o" aria-hidden="true"></i>
        </a>
    </div>
</template>

<script>

export default {
    name: 'DayNightSwitcher',
    data() {
        return {
            darkMode: false,
        }
    },
    methods: {
        changeMode(){
            this.darkMode = !this.darkMode;
            this.$store.commit('setDarkMode', this.darkMode);
            if(this.darkMode)
                this.setNightMode();
            else
                this.setDayMode();
        },
        setMode(isDark = false) {
            this.darkMode = isDark;
            this.$store.commit('setDarkMode', this.darkMode);
            if(this.darkMode)
                this.setNightMode();
            else
                this.setDayMode();
        },
        setDayMode() {
            document.getElementsByTagName('body')[0].classList.remove('dark-mode');
            this.$root.$cookies.set('dark-mode', 0,"10y")
        },
        setNightMode() {
            document.getElementsByTagName('body')[0].classList.add('dark-mode');
            this.$root.$cookies.set('dark-mode', 1,"10y")
        }
    },
    mounted() {
        this.setMode(this.$root.$cookies.get('dark-mode') == 1);
    }
}
</script>
<style>
    
</style>