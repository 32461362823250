import { useToast } from "vue-toastification";
import User from '@/models/User'
import userService from '@/services/user.service'

const toast = useToast();

// initial state
const state = {
    user: {
        id: 0,
        name: "",
        roles: [],
        permissions: [],
        token: null
    }
}

// getters
const getters = {

}

// mutations
const mutations = {
    setUser (state, userInfo=null) {
        state.user = new User(userInfo)
    }
}

// actions
const actions = {
    login ({ commit }, payload) {
        userService.login(payload.credentials).then(response => {
            commit('setUser', response)
            //localStorage.setItem('user_token', (response.token) ? response.token : '')
            localStorage.setItem('user', JSON.stringify(response))
            // payload.router.push({name:'dashboard'}) //TODO - разобраться почему не хочет работать маршрутизация после перехода 
            window.location.href = "/dashboard"
        }).catch((error) => {
            toast.error("login_error")
        })
    },
    
    logout ({ commit }, router) {
        userService.logout().then(response => {
            commit('setUser', new User())
            localStorage.removeItem('user');
            router.push({name:'home'})
            // window.location.href = "/"
        }).catch((error) => {
            toast.error("logout_error")
        })
    },
    
    checkUserBySession({ commit }, router) {
        userService.getUserBySession().then(response => {
            commit('setUser', response)
            localStorage.setItem('user', JSON.stringify(response))
            // router.push({name:'dashboard'}) //TODO - разобраться почему не хочет работать маршрутизация после перехода 
            window.location.href = "/dashboard"
        }).catch((error) => {
            
        })
    },

    checkUserByToken({ commit }, payload) {
        userService.getUserByToken(payload.token).then(response => {
            commit('setUser', response)
            localStorage.setItem('user', JSON.stringify(response))
            payload.router.push({name:'dashboard'})
            // window.location.href = "/dashboard"
        }).catch((error) => {
            
        })
    },

    getUserBySession({ commit }, router) {
        userService.getUserBySession().then(response => {
            commit('setUser', response)
            localStorage.setItem('user', JSON.stringify(response))
        }).catch((error) => {
            router.push({name:'home'})
            // window.location.href = "/"
        })
    },

    getUserByToken({ commit }, payload) {
        userService.getUserByToken(payload.token).then(response => {
            commit('setUser', response)
            localStorage.setItem('user', JSON.stringify(response))
        }).catch((error) => {
            payload.router.push({name:'home'})
            // window.location.href = "/"
        })
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}