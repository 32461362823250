<template>
    <a class="nav-link" href="#" role="button" @click="toggleFullscreen">
        <i class="fa fa-arrows-alt" aria-hidden="true"></i>
    </a>
</template>
  
<script>
  export default {
    data() {
      return {
        isFullscreen: false
      };
    },
    methods: {
        toggleFullscreen() {
            this.isFullscreen = !this.isFullscreen;
            if(this.isFullscreen)
                document.documentElement.requestFullscreen();
            else
                document.exitFullscreen();
        },
    }
  };
  </script>
  
  <style scoped>
  
  </style>