<template>
<div class="home-layout-wrapper container main-layout">
    <header>
        <div class="home-header row">
            <div class="box_up_max col-12 d-none d-lg-block"></div>
            <div class="box col-12">
                <h1 class="title"><span class="d-none d-md-inline">{{ $t('home.welcome_to') }}</span> <span class="fw-bold">Hotel‑Office</span>!</h1>
                <p class="text">{{ $t('home.header_about') }}</p>
                <img src="/img/office.svg" class="img_max d-none d-lg-block">
                <img src="/img/office.svg" class="img_min d-block d-lg-none">
            </div>
        </div>
    </header>


    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <Pagetitle/>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <Breadcrumbs/>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <router-view />
        </div>
    </div>
    <!-- /.content -->

    <footer class="row p-3">
        <div class="col-12 footer">
            <a href="/login">©</a> SAMO-Soft
        </div>
    </footer>
</div>
</template>

<script>
import Pagetitle from "@/components/app/Pagetitle";
import Breadcrumbs from "@/components/app/Breadcrumbs";

export default {
    name: 'main-layout',
    components: {
        Pagetitle, Breadcrumbs
    },
}
</script>
